@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100..800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.App {
  text-align: center;
  background-color: #f15c1c;
}

.App-logo {
  height: 70vh;
  pointer-events: none;
}

.App-header {
  background-color: #f15c1c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FFFFFF;
}

.App-link {
  color: #61dafb;
}

.CTA-form-button{
  font-family: "Martian Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  padding: 16px 12px;
  border-radius: 12px;
  box-shadow: 6px 6px #000 ;
  transition: ease-out all 150ms;
  cursor: pointer;
  background-color: #FFFFFF;
  border: solid 2px #000;
  text-decoration: none;
  color: #000;
  &:hover{
    box-shadow: 10px 10px #000;
    background-color: #61A0AF;
    color: #000;
  }
  &:active{
    box-shadow: 4px 4px #000;
    background-color: #B07BAC;
    color: #FFF;
    transition: ease-out 50ms;
  }
  &:visited{
    color: #000;
  }
}

.CTA-header{
  margin: 16px 8px 16px 8px ;
  font-size: calc(10px + 2vmin);
}

.coming-soon-container{
  padding-bottom: 16px;
}

.coming-soon{
  font-size: calc(10px + 1vmin);
  color: #FFF;
}
